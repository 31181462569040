import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CallReducerState} from './stateTypes';

const initialState: CallReducerState = {
    activeCalls: [],
    activeVideoCallsList: [],
};

const SLICE_NAME = 'callReducer';
const {actions, reducer} = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        showCallNotification: (
            state,
            action: PayloadAction<{
                callFromHelpButton?: boolean;
                firstName?: string;
                incomingPhoneNumber?: string;
                lastName?: string;
                meetingId?: string;
                nurseFirstName?: string;
                nurseLastName?: string;
                patientSponsor?: string;
                patientState?: string;
                phoneType?: string;
                [key: string]: unknown;
            }>
        ) => {
            state.activeCalls = [...state.activeCalls, action.payload];
        },
        hideCallNotification: (state, action: PayloadAction<string>) => {
            state.activeCalls = state.activeCalls.length
                ? state.activeCalls.filter(({meetingId}) => meetingId !== action.payload)
                : [];
        },
        updateVideoNotifications: (state, action: PayloadAction<{[key: string]: unknown}[]>) => {
            const safePayload = action.payload ? action.payload : [];
            const activeAwsCall = state.activeCalls.length
                ? state.activeCalls.find(({meetingId}) => meetingId === 'awsCall')
                : null;

            state.activeCalls = activeAwsCall ? [...safePayload, activeAwsCall] : safePayload;
        },
        updateVideoCallsList: (state, action: PayloadAction<{[key: string]: unknown}[]>) => {
            state.activeVideoCallsList = action.payload;
        },
        clearReducerData: () => initialState,
    },
});

export const {
    showCallNotification,
    hideCallNotification,
    updateVideoNotifications,
    clearReducerData,
    updateVideoCallsList,
} = actions;

export default reducer;
