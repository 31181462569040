import {StoreStateShape} from '@/redux/store/storeStateTypes';

export const navigationSelector = ({
    commonUserDataReducer: {
        routes,
        user,
        employment,
        loadingHandler: {loadInitialUserDataLoading},
    },
}: StoreStateShape) => ({
    routes,
    email: user?.email,
    nurseId: employment?.id,
    loading: loadInitialUserDataLoading,
});
