import {PatientResponseDTO} from '@/types/gatewayDataModels';

const prefix = '';

const setParamToLocalStorage = <V>(param: string, value: V) =>
    localStorage.setItem(`${prefix}${param}`, JSON.stringify(value));

const getParamFromLocalStorage = <V = any>(name: string, defaultValue?: V): V => {
    try {
        return JSON.parse(localStorage[name]);
    } catch (e) {
        return defaultValue;
    }
};

const addUserToLocalStorage = (data: PatientResponseDTO) => {
    try {
        const lastPatients = getParamFromLocalStorage('lastPatients', []);
        const patient = lastPatients.find((item) => item.id === data.id);

        if (patient) lastPatients.splice(lastPatients.indexOf(patient), 1);
        if (lastPatients.length > 14) lastPatients.pop();

        lastPatients.unshift(data);
        localStorage.setItem('lastPatients', JSON.stringify(lastPatients));
    } catch (e) {
        localStorage.removeItem('lastPatients');
    }
};

export {addUserToLocalStorage, getParamFromLocalStorage, setParamToLocalStorage};
