import {DATE_FORMAT_API} from '@/constants/timeFormats';
import {AppStartListening} from '@/redux/store/listenerMiddleware';
import appointmentsService from '@/services/appointmentsService';
import patientService from '@/services/patientService';
import {formatDateTime} from '@/utils/timeFormatter';

import {
    getAppointments,
    getAppointmentsFail,
    getAppointmentsSuccess,
    getPatient,
    getPatientFail,
    getPatientKits,
    getPatientKitsFail,
    getPatientKitsSuccess,
    getPatientSuccess,
} from './slice';

export default (startListening: AppStartListening) => {
    startListening({
        actionCreator: getAppointments,
        effect: async (action, listenerApi) => {
            try {
                const {scheduleDate, patientId} = action.payload;
                const apiDate = formatDateTime(scheduleDate, DATE_FORMAT_API);
                const response = await appointmentsService.getAppointmentByDate({apiDate, patientId});
                listenerApi.dispatch(
                    getAppointmentsSuccess(response ? response.filter(({title, state}) => title || state) : [])
                );
            } catch (err) {
                listenerApi.dispatch(getAppointmentsFail());
            }
        },
    });
    startListening({
        actionCreator: getPatientKits,
        effect: async (action, listenerApi) => {
            try {
                const response = await patientService.getPatientKits({patientId: action.payload});
                listenerApi.dispatch(getPatientKitsSuccess(response));
            } catch (err) {
                listenerApi.dispatch(getPatientKitsFail());
            }
        },
    });
    startListening({
        actionCreator: getPatient,
        effect: async (action, listenerApi) => {
            const {patientId} = action.payload;
            try {
                const response = await patientService.getPatient({patientId});
                listenerApi.dispatch(getPatientSuccess(response));
            } catch (err) {
                listenerApi.dispatch(getPatientFail());
            }
        },
    });
};
