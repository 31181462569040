import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {DischargeIcon, PrecautionIcon} from '@/assets/icons';
import {MHAvatar, MHTypography} from '@/components/base';
import routes from '@/constants/routes';
import {PatientResponseDTO} from '@/types/gatewayDataModels';

import {featureFlagSelector} from '../../redux/selectors';
import {InfoItem} from './InfoItem';

import styles from './patientListItem.module.scss';

type PatientListItemProps = {
    patient: PatientResponseDTO;
};

export const PatientListItem = ({patient}: PatientListItemProps) => {
    const history = useHistory();

    const isPrecautionsFeatureEnabled = useSelector(featureFlagSelector);

    const goToPatientPage = (id: number) => {
        sessionStorage.setItem('previousHistoryFromLastPatients', true.toString());
        history.push(routes.PATIENT_SINGLE_OVERVIEW(id.toString()));
    };

    const isDischargedPatient = patient.discharge?.date;
    const hasPrecautions = isPrecautionsFeatureEnabled && !!patient.activePrecautions?.length;
    const isDemoPatient = patient.demoPatient;
    const isVoidedPatient = patient.voided;

    return (
        <div className={styles.itemContentContainer} onClick={() => goToPatientPage(patient.id)}>
            <div className={styles.avatarContainer}>
                <MHAvatar customStyles={{root: styles.avatar}} alt={`${patient.lastName}/${patient.firstName}`} />
            </div>
            <div className={styles.dataContainer}>
                <div className={styles.dataTitleContent}>
                    {isDischargedPatient && (
                        <span className={styles.patientIcon}>
                            <DischargeIcon />
                        </span>
                    )}
                    <span className={styles.name}>
                        <MHTypography variant="headline5">{`${patient.lastName}, ${patient.firstName}`}</MHTypography>
                    </span>
                    <div className={styles.patientStatuses}>
                        {hasPrecautions && (
                            <span className={styles.patientIcon}>
                                <PrecautionIcon />
                            </span>
                        )}
                        {isDemoPatient && <span className={styles.patientStatus}>Demo</span>}
                        {isVoidedPatient && <span className={styles.patientStatus}>Voided</span>}
                    </div>
                </div>
                <InfoItem
                    birthDate={patient.birthDate}
                    townName={patient.info?.homeAddress?.town?.name}
                    sex={patient.sex}
                    product={patient.product}
                />
            </div>
            <div className={styles.icon}>
                <ChevronRightIcon fontSize="inherit" />
            </div>
        </div>
    );
};
