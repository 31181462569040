import {shallowEqual, useSelector} from 'react-redux';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import classnames from 'classnames';

import {MHToolTip} from '@/components/base';
import {outboundCall} from '@/services/awsConnectApiService';
import zoomService from '@/services/zoomService';
import {MissedCallSummary} from '@/types/observationsModels';

import {AGENT_STATUSES} from '../../../shared/constants';
import {callsSelector} from '../../redux/selectors';

import styles from './callActions.module.scss';

type CallActionsProps = {
    patientId: number;
    patientFirstName: string;
    patientLastName: string;
    callType: MissedCallSummary['type'];
    hasActiveKitAssigned: boolean;
    latestPhoneNumber: string;
};

export const CallActions = ({
    patientId,
    patientFirstName,
    patientLastName,
    callType,
    hasActiveKitAssigned,
    latestPhoneNumber,
}: CallActionsProps) => {
    const {awsConnectAgentStatus, email} = useSelector(callsSelector, shallowEqual);
    const videoCallDisabled = awsConnectAgentStatus === AGENT_STATUSES.OFFLINE || !hasActiveKitAssigned;
    const audioCallDisabled =
        window.DISABLE_AWS_LOGIN === 'true' || awsConnectAgentStatus === AGENT_STATUSES.OFFLINE || !latestPhoneNumber;
    const getVideoCallTooltip = () => {
        const callType = 'Video Call';
        if (!hasActiveKitAssigned) {
            return `${callType} (issue with phone number)`;
        }
        if (videoCallDisabled) {
            return `${callType} (unavailable in Offline mode)`;
        }
        return callType;
    };

    return (
        <>
            {callType === 'VIDEO' || callType === 'PERS' ? (
                <MHToolTip tooltipContent={getVideoCallTooltip()} light>
                    <VideocamOutlinedIcon
                        onClick={(event) => {
                            event.stopPropagation();
                            if (!videoCallDisabled) {
                                zoomService.createZoomMeeting(patientId);
                            }
                        }}
                        className={classnames(styles.callIcon, {
                            [styles.disabledCallIcon]: videoCallDisabled,
                        })}
                    />
                </MHToolTip>
            ) : (
                <MHToolTip
                    tooltipContent={`Phone Call ${audioCallDisabled ? '(unavailable in Offline mode)' : ''}`}
                    light
                >
                    <LocalPhoneOutlinedIcon
                        className={classnames(styles.callIcon, {
                            [styles.disabledCallIcon]: audioCallDisabled,
                        })}
                        onClick={(event) => {
                            event.stopPropagation();
                            if (!audioCallDisabled) {
                                outboundCall(latestPhoneNumber, {
                                    mcrnEmail: email,
                                    patientId,
                                    patientFirstName,
                                    patientLastName,
                                });
                            }
                        }}
                    />
                </MHToolTip>
            )}
        </>
    );
};
