import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

import urls from '@/constants/endpoints/gateway';
import {LoggingActions} from '@/constants/loggingActions';
import {showCallNotification, updateVideoCallsList, updateVideoNotifications} from '@/redux/commonCalls/slice';
import {store} from '@/redux/store';
import audioServiceFactory from '@/services/createAudioNodes';
import {loggerService} from '@/services/loggerService';

import awsConnectService from './awsConnectApiService';
import httpApi from './httpApi_new';

class ZoomService {
    _meetingId = +sessionStorage.getItem('meetingId') || null;

    get meetingId() {
        return this._meetingId;
    }

    set meetingId(value) {
        if (value) {
            sessionStorage.setItem('meetingId', value);
        } else {
            sessionStorage.removeItem('meetingId');
        }
        this._meetingId = value;
    }

    constructor() {
        this.history = null;
        this.baseShortPolling = 5000;
        this.patientVideoCallStatusShortPolling = 10000;
        this.incomingZoomCallsShortPolling = null;
        this.videoCallStatusPollingId = null;

        if (this.meetingId) {
            this.openZoomNativeAppUrl({
                meetingId: this.meetingId,
            });
        }
    }

    setHistory = (history) => (this.history = history);

    /**
     * @param {import('@/types/gatewayDataModels').ZoomMeetingResponseDTO} zoomMeeting
     */
    openZoomNativeAppUrl = ({meetingId, startUrl}) => {
        this.clearVideoCallStatusPollingId();
        awsConnectService.onAVideoCall(() => {
            window.awsConnectService.setStoreStatus('On a Video Call');
            this.shortPollingMCRNStatus(meetingId);
        });

        if (!startUrl) return;

        // Just in case this.history.push(routes.PATIENT_SINGLE(zoomMeeting.patientId));
        let win = window.open(startUrl, '_blank');
        win.focus();
        setTimeout(() => {
            win.close();
        }, 15000);
    };

    acceptZoomMeeting = (meetingId) => {
        httpApi.put({
            url: `${urls.ZOOM}/${meetingId}/accept`,
        });
    };

    declineZoomMeeting = async (meetingId) => {
        await httpApi.put({
            url: `${urls.ZOOM}/${meetingId}/decline`,
        });
        this._queryVideoCallStatus();
    };

    createZoomMeeting = (patientId) => {
        httpApi
            .post({
                url: `${urls.ZOOM}/${patientId}`,
            })
            .then((res) => {
                window.DISABLE_AWS_LOGIN === 'true' && this.acceptZoomMeeting(res.meetingId);
                this.openZoomNativeAppUrl(res);
            });
    };

    initiateShortPolling = () => {
        this.incomingZoomCallsShortPolling = setInterval(this._queryVideoCallStatus, this.baseShortPolling);

        loggerService.logEvent({
            severity: 'Info',
            email: store.getState().commonUserDataReducer?.user?.email,
            action: LoggingActions.zoomInitiateShortPolling,
            message: `Zoom short polling initialized with id ${
                this.incomingZoomCallsShortPolling
            }. AWS connect status was: in store - ${
                store.getState().commonUserDataReducer.phoneCallData?.awsConnectAgentStatus
            }; in awsClient - ${window.myCPP?.agent?.getState()?.name}`,
        });
    };

    _queryVideoCallStatus = () => {
        let agent = get(window, 'myCPP.agent', null);
        const awsStatus = store.getState().commonUserDataReducer.phoneCallData?.awsConnectAgentStatus;
        if (
            (agent !== null &&
                isFunction(agent.getState) &&
                agent.getState().name !== 'Offline' &&
                agent.getState().name !== 'On a Video Call') ||
            (window.DISABLE_AWS_LOGIN === 'true' && awsStatus !== 'Offline' && awsStatus !== 'On a Video Call')
        ) {
            httpApi
                .get({
                    url: urls.ZOOM,
                })
                .then((newArray) => {
                    const oldArray = store.getState().callReducer.activeVideoCallsList;
                    const oldArrayLength = oldArray.length;
                    const newArrayLength = newArray.length;
                    if (oldArrayLength < newArrayLength) {
                        const callerPatient = newArray[newArray.length - 1];
                        this._notifyOnCall(callerPatient);
                    } else if (oldArrayLength > newArrayLength) {
                        store.dispatch(updateVideoNotifications(newArray));
                    }
                    store.dispatch(updateVideoCallsList(newArray));
                });
        }
    };

    clearIncomingZoomCallsShortPolling = () => {
        clearInterval(this.incomingZoomCallsShortPolling);

        loggerService.logEvent({
            severity: 'Info',
            email: store.getState().commonUserDataReducer?.user?.email,
            action: LoggingActions.zoomStopShortPolling,
            message: `Zoom short polling with id ${
                this.incomingZoomCallsShortPolling
            } was stopped. AWS connect status was: in store - ${
                store.getState().commonUserDataReducer.phoneCallData?.awsConnectAgentStatus
            }; in awsClient - ${window.myCPP?.agent?.getState()?.name}`,
        });

        this.incomingZoomCallsShortPolling = null;
    };

    clearVideoCallStatusPollingId = () => {
        clearInterval(this.videoCallStatusPollingId);
        this.videoCallStatusPollingId = null;
    };

    shortPollingMCRNStatus = (zoom_meeting_id) => {
        this.meetingId = zoom_meeting_id;

        this.videoCallStatusPollingId = setInterval(() => {
            httpApi
                .get({
                    url: `${urls.ZOOM}/${zoom_meeting_id}/status`,
                })
                .then((res) => {
                    if (
                        res.activeUserStatus === 'LEFT' ||
                        res.meetingStatus === 'ENDED' ||
                        res.meetingStatus === 'CANCELED' ||
                        res.meetingStatus === 'SUSPENDED'
                    ) {
                        const agentState = window.myCPP?.agent?.getState();
                        const agentStateFromTheStore =
                            store.getState().commonUserDataReducer.phoneCallData.awsConnectAgentStatus;
                        if (agentState?.name === 'On a Video Call' || agentStateFromTheStore === 'On a Video Call') {
                            awsConnectService.goAvailable(() => {
                                this.meetingId = null;
                                this.clearVideoCallStatusPollingId();
                                awsConnectService.setStoreStatus('Available');
                            });
                        } else {
                            this.meetingId = null;
                            this.clearVideoCallStatusPollingId();
                        }
                    }
                })
                .catch(() => {
                    this.clearVideoCallStatusPollingId();
                });
        }, this.baseShortPolling);
    };

    handleAcceptCall = (caller) => {
        const acceptCall = () => {
            this.openZoomNativeAppUrl(caller);
            this.acceptZoomMeeting(caller.meetingId);
            store.dispatch(updateVideoNotifications([]));
        };
        if (awsConnectService.checkIfConnectionExists()) {
            awsConnectService.disconnectContact(acceptCall);
        } else {
            acceptCall();
        }
    };

    _notifyOnCall = (callerPatient) => {
        const {stopNodes, playNodes, createAudioNodes} = audioServiceFactory();
        createAudioNodes(
            () => {
                store.dispatch(showCallNotification({...callerPatient, mountCb: playNodes, unmountCb: stopNodes}));
            },
            {loop: true}
        );
    };

    removePatientStatusShortPolling = (id) => {
        clearInterval(id);
    };

    initiatePatientStatusShortPolling = (patientId, callback) => {
        this._checkPatientVideoCallStatus(patientId, callback);
        return setInterval(
            () => this._checkPatientVideoCallStatus(patientId, callback),
            this.patientVideoCallStatusShortPolling
        );
    };

    _checkPatientVideoCallStatus = (patientId, callback) => {
        httpApi
            .get({url: urls.ZOOM_ACTIVE(patientId)})
            .then(({active}) => ({data: active}))
            .then(callback);
    };
}

export default new ZoomService();
