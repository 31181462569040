import {shallowEqual, useSelector} from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import classnames from 'classnames';

import {MHTypography} from '@/components/base';
import {getActiveCalls} from '@/redux/commonCalls/selectors';
import {ActiveCall} from '@/redux/commonCalls/types';

import {IncomingAWSCall} from './IncomingAWSCall';
import {IncomingAWSHelpCall} from './IncomingAWSHelpCall';
import {IncomingZoomCall} from './IncomingZoomCall';

import styles from './callNotification.module.scss';

export const CallNotification = () => {
    const {activeCalls, states} = useSelector(getActiveCalls, shallowEqual);

    const AWS_CALL_ID = 'awsCall';

    const renderCallNotification = (callData: ActiveCall) => {
        const {meetingId, callFromHelpButton} = callData;
        const isHelpCall = meetingId === AWS_CALL_ID && callFromHelpButton;
        const isRegularCall = meetingId === AWS_CALL_ID && !callFromHelpButton;
        const isZoomCall = meetingId !== AWS_CALL_ID;

        if (isRegularCall) return <IncomingAWSCall callData={callData} />;
        if (isZoomCall) return <IncomingZoomCall callData={callData} />;
        if (isHelpCall) return <IncomingAWSHelpCall callData={callData} />;
    };

    const renderAllCallNotifications = () =>
        activeCalls.map((callData) => {
            const {meetingId, callFromHelpButton, patientState} = callData;
            const showDiffStatesWarning =
                meetingId === AWS_CALL_ID &&
                patientState &&
                !!states?.length &&
                !states.find((state) => state.code === patientState);

            return (
                <div
                    key={meetingId}
                    className={classnames(styles.wrapper, {
                        [styles.helpWrapper]: callFromHelpButton,
                        [styles.diffStatesWrapper]: showDiffStatesWarning,
                    })}
                >
                    {renderCallNotification(callData)}
                    {showDiffStatesWarning && (
                        <div className={styles.diffStatesWarning}>
                            <InfoOutlinedIcon />
                            <MHTypography variant="bodyM">
                                This patient is calling from a state that you are not licensed in.
                            </MHTypography>
                        </div>
                    )}
                </div>
            );
        });

    return activeCalls.length ? <div className={styles.callNotifications}>{renderAllCallNotifications()}</div> : null;
};
