import {AppStartListening} from '@/redux/store/listenerMiddleware';

import getCommonDataService from '../../services/commonDataService';
import {getCommonData, getCommonDataFail, getCommonDataStart, getCommonDataSuccess} from './slice';

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getCommonData,
        effect: async (action, listenerApi) => {
            const toLoad = getCommonDataService.checkLoad(action.payload);
            if (toLoad.length) {
                listenerApi.dispatch(getCommonDataStart(toLoad));
                try {
                    const data = await getCommonDataService.getCommonData(toLoad);
                    listenerApi.dispatch(getCommonDataSuccess({data, keys: toLoad}));
                } catch (error) {
                    listenerApi.dispatch(getCommonDataFail(toLoad));
                }
            } else {
                //we need delay here for proper work of listenerApi.condition (to make this action asynchronous)
                await listenerApi.delay(1);
                listenerApi.dispatch(getCommonDataSuccess());
            }
        },
    });
};

export default addFeatureListeners;
